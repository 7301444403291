// Dependency
import React, {useState} from "react"
import { useCart } from '../../../contexts/cart-context';
//import CartAPI from '../../../services/cart.service';

// Components
import MiniLoader from "../../loader/mini-loader";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as productStyles from '../../../templates/product/product.module.scss';

// Template
const ProductAddToCart = (props) => {
    const { addToCart } = useCart()
    // Translation Function
    const { t } = useTranslation();

    const [ isLoading, setIsLoading ] = useState(false)

    const itemID = props.isVariable ? props.variant.value : props.itemId;

    let translationSelect = t('productCard.setSize');
    let buyButton = t('productInfo.buyNow');
    let oos = false;

    const handleAddToCart = async () => {
        const res = await addToCart(itemID, 'Single Product', props.centra);
        if ( res ) setIsLoading(false)
    }

    if(props.stock === "no"){
        buyButton = t('productInfo.oos')
        oos = true;
    }

    return (
        props.isVariable && ! props.variant ? <button className={` ${productStyles.cdAtcButton} cd-atc-button button`}>{translationSelect}</button> : 
        <button disabled={oos} className={` ${productStyles.cdAtcButton} cd-atc-button button`} onClick={() => {
            setIsLoading(true);
            handleAddToCart()
        }}>
            {buyButton}
            {!props.noVatPrice && !oos ?
                <span><span className="">&nbsp;-&nbsp;{props.isVariable ? props.variant.price : props.price}</span></span>
                :
                ''
            }
            <MiniLoader active={isLoading} />
        </button>
  )
};

export default ProductAddToCart;