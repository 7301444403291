// Dependency
import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import SwiperCore, { Navigation, Pagination } from 'swiper';
//import Select from 'react-select';

// Services
import GA from "../../services/ga.service";

// Hooks
//import useProgramData from "./hooks/useProgramData";
import ProductData from "./hooks/product-data";
import useMediaQuery from '../../hooks/useMediaQuery';

// Components
import Layout from "../../components/layouts";
import MediaBanner from "../../components/media-banner";
import ProductSlider from "../../components/product/product-slider/product-slider";
import ProductPrice from "../../components/product/product-price/product-price";
import ProductPerksImage from "../../components/product/product-perks-img/product-perks-img";
import ProductPearksModal from "../../components/product/product-pearks-modal/product-pearks-modal";
import ProductContent from "../../components/product/product-content/product-content";
import ProductTabs from "../../components/product/product-tabs/product-tabs";
import ProductAddToCart from "../../components/product/product-add-to-cart/product-add-to-cart";
import BundleProducts from "../../components/product/bundle/bundle-products";
import TestFreak from "../../components/testfreak/testfreak";
import ProductGallery from "../../components/product/product-gallery/product-gallery";
import ProductSwiper from "../../components/product-swiper";
import Countdown from "../../components/countdown/countdown";
import { ProductWithReviewsStructuredData } from "../../components/seo/ProductWithReviewsStructuredData";
import useTestFreakReviewsApi from "../../hooks/useTestFreakReviewsApi";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import 'swiper/scss';
import 'swiper/scss/navigation';

import * as productStyles from './product.module.scss';

// Assets
import ProductList from "./hooks/product-list";

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

//GraphQL to get Centra and Dato data
export const query = graphql`
  query (
    $centraId: Int!,
    $pricelist: [Int!],
    $lang: [Int!],
    $locale: String! = "en"
   ){
    centra {
      product (id: $centraId) {
      displays{
        id
        displayItems {
          id
          productVariant {
            name
            productSizes {
              id
              quantity
            }
          }
        }
        name
        shortDescription
        description
        media {
          id
          source {
            url
          }
        }
        categories {
          id
          name
          uri
        }
        prices(where: { pricelistId: $pricelist }) {
          id
          price {
            formattedValue
            value
            currency {
              code
            }
          }
        }
        translations(where: { languageId: $lang }) {
          language {
            id
            name
            countryCode
            languageCode
          }
          fields {
            field
            value
          }
        }
      }
      brand {
        name
      }
    }
    }
    datoCmsProduct (centraId: { eq: $centraId },locale: {eq: $locale}) {
      seoMetaTags {
        tags
        id
      }
      locale
      centraId
      variableProduct
      productVariants {
        title
        seoSlug
        featuredImage {
          url
        }
      }
      title
      slug
      desc
      trustpilotId
      productType
      productCategory {
        name
        slug
      }
      infoPerks{
        image{
          url
        }
        text
      }
      featuredImage{
          fluid{
            ...GatsbyDatoCmsFluid
          }
      }
      gallery{
          fluid{
            ...GatsbyDatoCmsFluid
          }
          url
          isImage
      }
      galleryVideo{
        url
      }
      storePerks{
        image{
          url
          format
          fixed(height: 18) {
            ...GatsbyDatoCmsFixed
          }
        }
        text
      }
      newPrice
      bundleSaleText
      shoppingExperience
      triggerbee
      longDescription
      lowstock
      bulletpoints
      b2bProduct
      noVatPrice
      vatPrice
      mediaBanner{
        desktopImage{
          fluid{
            ...GatsbyDatoCmsFluid
          }
          url
        }
        mobileImage{
          fluid{
            ...GatsbyDatoCmsFluid
          }
          url
        }
        bannerText
        video{
          url
        }
        mobileVideo{
          url
        }
        audiobleVideo{
          url
        }
        audibleVideoMobile{
          url
        }
        iframe{
         url
        }
      }
      iconPerks{
        text
        image{
          url
        }
      }
      relatedHeadline
      products{
        centraId
        slug
        featuredImage{
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        newPrice
        locale
        title
        parentProductName
        trustpilotId
        saleBadge
        bestseller
      }
      rowGrid{
        image{
            fluid{
            ...GatsbyDatoCmsFluid
          }
        }
        video{
          url
        }
        title
        content
        link
        linkText
        textleft
        beige
      }
      boxContent
      boxImage{
        url
      }
      pins{
        image{
          fluid{
            ...GatsbyDatoCmsFluid
          }
        }
        content
        positionTop
        positionLeft
      }
      bundleProducts{
        featuredImage{
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        gallery{
          fluid{
            ...GatsbyDatoCmsFluid
          }
          url
          isImage
        }
        slug
        title
        desc
        productType
        trustpilotId
        centraId
      }
      modalPerks{
        title
        content
        contenttitle
      }
    }
  }
`


// Template
const ProductTemplate = ({ data, pageContext, location }) => {
  // Product data from plugin
  const productData = data.centra.product?.displays[0] ?? {
    "id": 23,
    "displayItems": []
  };
  const productDatoCMS = data.datoCmsProduct;
  const reviews = useTestFreakReviewsApi(data.datoCmsProduct.trustpilotId, pageContext.country)

  const mediaBannerSources = [
    productDatoCMS.mediaBanner && productDatoCMS.mediaBanner.mobileImage && productDatoCMS.mediaBanner.mobileImage.fluid,
    productDatoCMS.mediaBanner && productDatoCMS.mediaBanner.desktopImage &&
    {
      ...productDatoCMS.mediaBanner.desktopImage.fluid,
      media: `(min-width: 900px)`,
    },
  ]

  // Product data from centra over and ajax call
  const [productDataAjax, setProductDataAjax] = useState({});
  const [price, setPrice] = useState(0);
  const [variants, setVariants] = useState([]);
  const [variant, setVariant] = useState(false);

  const [productList, setProductList] = useState([]);
  const [impression, setImpression] = useState(false);

  // Translation Function
  const { t } = useTranslation();

  useEffect(() => {
    if (productDataAjax && productDataAjax.price) {
      if (!impression) {
        GA.onDetailView(productDataAjax, 'Single Product');
        setImpression(true);
      }
    }
  }, [productDataAjax]);


  // SELECTS VARIANT
  const transDefault = productDatoCMS.centraId === 4 ? t('productCard.setColor') : t('productCard.setSize');
  let defaultValue = [
    { value: 0, label: transDefault }
  ]

  let v = [];

  const changeVariant = (value, price) => {
    setVariant({ value: value, price: price });
  }

  const handleProductData = (data) => {
    setProductDataAjax(data[0]);
    let price = '';
    let prevPrice = '';
    for (const [i, prod] of data.entries()) {
      if (data.length > 1 && i === 1 && (prevPrice !== prod.price)) price += ' - ';
      if (prevPrice !== prod.price) price += prod.price;
      prevPrice = prod.price;
      v.push({ value: prod.items[0].item, label: prod.variantName, price: prod.price, img: prod.media.standard[0] })
    }
    setVariants(v)
    if (!variant) setVariant(v[0]);
    setPrice(price);
  }

  const [voucher, setVoucher] = useState(false);

  //Add voucher if url param
  useEffect(() => {
    //GET URL params
    const urlParams = new URLSearchParams(location.search);
    const urlvoucher = urlParams.get('code');
    if (urlvoucher) {
      setVoucher(urlvoucher)
    }
  }, []);

  const isTabletOrMobile = useMediaQuery(900);

  const klarnaPrice = productDataAjax.priceAsNumber * 100

  return (
    <Layout
      isAbsolute={true}
      seoData={productDatoCMS.seoMetaTags}
      productData={productData}
      country={pageContext.country}
      location={location}>
      <ProductData voucher={voucher} country={pageContext.country} items={productData.displayItems} setProductData={handleProductData} />
      <ProductList createProductList={setProductList} />
      <ProductWithReviewsStructuredData productData={data} reviews={reviews} />
      <div className={` ${productStyles.cdSingleProductWrapper} cd-single-product-wrapper`}>
        <section>
          <div className={` ${productStyles.cdSingleProductTop} cd-single-product-top cd-swiper-wrapper cd-max-width cd-xl-width`} >
            <div className={productStyles.cdProductLeft}>
              {isTabletOrMobile ?
                <ProductSlider video={productDatoCMS.galleryVideo} images={productDatoCMS.gallery} />
                :
                <ProductGallery video={productDatoCMS.galleryVideo} images={productDatoCMS.gallery} />
              }
              <div className="cd-countdown-banner">
                <Countdown country={pageContext.country} />
              </div>
            </div>
            <div className={`${productStyles.cdProductRight}`}>
              <div className={productStyles.cdSingleProductContent}>
                <div className={`${productStyles.cdSingleProductContentContainer} cd-dark-mode-dark`}>
                  <div className={productStyles.cdProductRightInner}>
                    <div>
                      {
                        productDatoCMS && productDatoCMS.bundleSaleText ? <span className={productStyles.cdSingleProductCat}>{productDatoCMS.bundleSaleText}</span>
                          :
                          productDatoCMS && productDatoCMS.productType ? <span className={productStyles.cdSingleProductCat}>{productDatoCMS.productType}</span>
                            :
                            ''
                      }

                      <div className={`${productStyles.cdSingleProductContentTop} cd-single-product-top`}>
                        <div className={productStyles.cdSingleProductContentLeft}>
                          <h1>{productDatoCMS.title}</h1>
                          {/* <TestFreak
                          props={{
                            reviews: false,
                            type: "badge",
                            productId: productDatoCMS.trustpilotId,
                            country: pageContext.country,
                          }}
                        /> */}
                        </div>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: productDatoCMS.desc }}></div>
                      <ProductPerksImage imagePerks={productDatoCMS.infoPerks} />

                      <div className={productStyles.cdSingleProductPriceInfo}>
                        <ProductPrice discountPercent={productDataAjax.discountPercent} noVatPrice={productDatoCMS.noVatPrice} newPrice={productDatoCMS.newPrice} isVariable={productDatoCMS.variableProduct} variant={productDatoCMS.variableProduct ? variant : false} priceBeforeDiscount={productDataAjax.priceBeforeDiscount} price={price} />
                        {productDatoCMS && productDatoCMS.lowstock ?
                          <div className={`${productStyles.cdSingleProductStock} ${productStyles.cdSingleProductLowStock}`}><span></span><p>{t('productInfo.lowStock')}</p></div>
                          :
                          productDataAjax && productDataAjax.items && productDataAjax.items[0].stock === "no" ? <div className={`${productStyles.cdSingleProductStock} ${productStyles.cdSingleProductNoStock}`}><span></span><p>{t('productInfo.oos')}</p></div> : <div className={productStyles.cdSingleProductStock}><span></span><p>{t('productInfo.inStock')}</p></div>
                        }

                      </div>
                      {/* This component will fetch price over ajax call to centra */}

                      {productDatoCMS && productDatoCMS.triggerbee ? <div dangerouslySetInnerHTML={{ __html: productDatoCMS.triggerbee }}></div> : null}

                    </div>
                    {productDatoCMS.variableProduct ?
                      <div>
                        <label className={productStyles.cdProductSelectLabel}><span>Variant: </span>
                          {productDatoCMS.productVariants.map((title, i) => {
                            const titleArray = title.title.split(' ')
                            const variantTitle = titleArray[titleArray.length - 1]
                            return (
                              <span key={i}>{title.seoSlug === productDatoCMS.slug ? variantTitle : ''}</span>
                            )
                          })}
                        </label>
                        <div className={productStyles.cdProductSelectWrapper}>
                          {productDatoCMS.productVariants.map((va, i) => {

                            return (
                              <Link key={i} to={`/${pageContext.country}/${t('slug.product')}/${va.seoSlug}/`} className={va.seoSlug === productDatoCMS.slug ? `${productStyles.cdProductSelectItem} ${productStyles.cdProductSelectItemActive}` : `${productStyles.cdProductSelectItem}`}>
                                <div className={`${productStyles.cdProductSelectLeft} cd-bacground-img`}>
                                  <img src={va.featuredImage.url} />
                                </div>
                              </Link>
                            )
                          })}
                        </div>
                      </div> : ''
                    }
                    <ProductAddToCart
                      isVariable={productDatoCMS.variableProduct}
                      variant={productDatoCMS.variableProduct ? variant : false}
                      price={price}
                      centra={productDataAjax}
                      itemId={productDataAjax && productDataAjax.items && productDataAjax.items[0].item}
                      item={productDatoCMS}
                      noVatPrice={productDatoCMS.noVatPrice}
                      stock={productDataAjax && productDataAjax.items && productDataAjax.items[0].stock}
                    />
                    <div className="triggerbee-section"></div>

                    {
                      productDatoCMS && productDatoCMS.bundleProducts.length > 0 ?
                        <BundleProducts locale={productDatoCMS.locale} data={productDatoCMS.bundleProducts} centraProducts={productList} />
                        :
                        <>
                          {pageContext.country === 'se' &&
                            <div className={productStyles.cdSingleProductKlarna}>
                              <klarna-placement
                                data-key="credit-promotion-auto-size"
                                data-locale="sv-SE"
                                data-purchase-amount={klarnaPrice}
                              ></klarna-placement>
                            </div>
                          }
                          <ProductPearksModal product={productDatoCMS} />
                        </>
                    }
                    <ProductTabs
                      desc={productDatoCMS.longDescription}
                      SKU={productDatoCMS.trustpilotId}
                      shopping={productDatoCMS.shoppingExperience}
                      bulletPoints={productDatoCMS.bulletpoints}
                      batteryText={productDatoCMS.batteryText}
                      batteryTime={productDatoCMS.batteryTime}
                      batteryType={productDatoCMS.batteryType}
                      performance={productDatoCMS.performance}
                      beatsPerMinute={productDatoCMS.beatsPerMinute}
                      loadTime={productDatoCMS.loadTime}
                      strokeLength={productDatoCMS.strokeLength}
                      motor={productDatoCMS.motor}
                      noiseLevel={productDatoCMS.noiseLevel}
                      maximumPressure={productDatoCMS.maximumPressure}
                      specifications={productDatoCMS.specifications}
                      weight={productDatoCMS.weight}
                      size={productDatoCMS.size}
                      dimensions={productDatoCMS.dimensions}
                      bag={productDatoCMS.bag}
                      charger={productDatoCMS.charger}
                      nozzle={productDatoCMS.nozzle}
                      locale={productDatoCMS.locale}
                      pins={productDatoCMS.pins}
                      boxContent={productDatoCMS.boxContent ? productDatoCMS.boxContent : ''}
                      imgBoxContent={productDatoCMS.boxImage ? productDatoCMS.boxImage.url : ""}
                      productReview={productDatoCMS && productDatoCMS.trustpilotId ? productDatoCMS.trustpilotId : ''}
                      title={productDatoCMS.title}
                      country={pageContext.country}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {
          productDatoCMS && productDatoCMS.mediaBanner ? <MediaBanner
            sources={mediaBannerSources}
            desktopImage={productDatoCMS.mediaBanner.desktopImage}
            mobileImage={productDatoCMS.mediaBanner.mobileImage}
            videoSrcURL={productDatoCMS.mediaBanner.video}
            videoSrcURLMobile={productDatoCMS.mediaBanner.mobileVideo}
            audibleVideo={productDatoCMS.mediaBanner.audiobleVideo}
            audibleVideoMobile={productDatoCMS.mediaBanner.audibleVideoMobile}
            iframe={productDatoCMS.mediaBanner.iframe}
          >
            <div dangerouslySetInnerHTML={{ __html: productDatoCMS.mediaBanner.bannerText }}></div>
          </MediaBanner> : ""
        }

      </div>

      { // visible only if data exist
        productDatoCMS && productDatoCMS.rowGrid.length ? <ProductContent country={pageContext.country} content={productDatoCMS.rowGrid} /> : ""
      }

      {productDatoCMS && productDatoCMS.products && productDatoCMS.products.length ?
        <section className="cd-home-product-swiper">
          <div className="cd-max-width">
            <div className="cd-heading">
              {productDatoCMS.relatedHeadline && <h2>{productDatoCMS.relatedHeadline}</h2>}
            </div>
            <ProductSwiper productList={productList} data={productDatoCMS.products} placement="Home - Best Sellers" />
          </div>
        </section>
        :
        ''
      }

    </Layout>
  )
};

export default ProductTemplate;