import React from "react"

export const ProductWithReviewsStructuredData = ({ productData, reviews }) => {
  const product = productData.centra.product?.displays[0]
  const brand = productData.centra.product?.brand
  const allSizesQuantity =
    product?.displayItems?.[0]?.productVariant.productSizes.reduce(
      (acc, curr) => {
        acc += curr.quantity
        return acc
      },
      0
    )
  if (!product || !reviews) {
    return <></>
  }
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Product",
    sku: productData.datoCmsProduct.trustpilotId,
    name: product.name,
    brand: brand.name,
    description: product.description,
    image: product.media[0].source.url,
    category: product.categories[0].name,
    color: product.color,
    availability:
      allSizesQuantity && allSizesQuantity > 0
        ? "https://schema.org/InStock"
        : "https://schema.org/OutOfStock",
    offers: [
      {
        "@type": "Offer",
        SKU: productData.datoCmsProduct.trustpilotId,
        url: `${window.location.origin}${window.location.pathname}`,
        priceCurrency: product.prices[0].price.currency.code,
        price: `${product.prices[0].price.value}`,
        availability:
          allSizesQuantity && allSizesQuantity > 0
            ? "https://schema.org/InStock"
            : "https://schema.org/OutOfStock",
        seller: {
          "@type": "Organization",
          name: brand.name,
        },
      },
    ],
    review: reviews?.reviews.map(review => ({
      "@type": "Review",
      "@id": `${review.feedback_url.split("/reviews/")[1].split("/")[0]}`,
      text: review.extract,
      reviewBody: review.extract,
      datePublished: review.date,
      reviewRating: {
        "@type": "Rating",
        ratingValue: review.score,
      },
      author: {
        "@type": "Person",
        name: review.author,
      },
    })),
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: reviews.your_avg_score,
      reviewCount: reviews.your_review_count,
    },
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  )
}
